<template>
  <div class="relative">
    <UPopover
      ref="popoverRef"
      :open="openMoreSetting"
      @update:open="onUpdateMoreSettingOpen"
    >
      <template #panel>
        <div class="p-4 min-w-[208px]">
          <template
            v-for="(actionGroup, groupIndex) in actionGroupLists"
            :key="actionGroup.title"
          >
            <p
              v-if="actionGroup.actions.length"
              :class="[
                'font-semibold text-gray-900 text-sm mb-2',
                { 'mt-6': groupIndex !== 0 },
              ]"
            >
              {{ actionGroup.title }}
            </p>
            <div class="flex flex-col gap-2">
              <template v-for="action in actionGroup.actions" :key="action.key">
                <TaskDetailTooltipButton
                  v-if="action.show ?? true"
                  :tooltip-text="action.tooltip"
                  :shortcuts="action.shortcuts"
                  :icon="action.icon"
                  :disabled="action.disabled"
                  :text="action.text"
                  v-bind="action.buttonProps"
                  @click="action.onClick"
                />
              </template>
            </div>
          </template>
        </div>
      </template>
      <UButton
        icon="i-heroicons-ellipsis-horizontal"
        color="gray"
        variant="ghost"
        :class="[
          'w-full text-xs bg-gray-100',
          isPopoverOpen ? '!bg-gray-200' : 'hover:bg-gray-200',
        ]"
        @click.prevent.stop="onShowMoreSetting"
      >
        More setting
      </UButton>
    </UPopover>
    <!-- Action Popover -->
    <div>
      <template v-for="action in filteredPopovers" :key="action.key">
        <component
          :is="action.component"
          :ref="action.ref"
          v-bind="action.popoverProps"
          @update:open="onUpdatePopoverOpen"
        >
          <div class="hidden" />
        </component>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SidebarActionGroup, TaskDetail } from '#task/types'

const props = defineProps({
  taskDetail: {
    type: Object as PropType<TaskDetail>,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'click-dependency'): void
  (e: 'click-subtasks'): void
}>()

const { actionGroupLists, expose, actions, isPopoverOpen, openMoreSetting } =
  useTaskDetailSidebar(props, emit)

const { onUpdateMoreSettingOpen, onUpdatePopoverOpen, onShowMoreSetting } =
  actions

const filteredPopovers = computed(() => {
  return actionGroupLists.value.reduce<SidebarActionGroup['actions']>(
    (acc, group) => {
      const popovers = group.actions.filter(
        (action) => (action.show ?? true) && !!action.component
      )
      return [...acc, ...popovers]
    },
    []
  )
})

defineExpose(expose)
</script>
